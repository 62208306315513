import { classes } from '@/uiPrimitives/base/classes.helpers';
import { pageGridBase, pageGridSprinkles } from './pageGrid.css';

export type PageGridProps = Parameters<typeof pageGridSprinkles>[0];

/**
 * The grid of the page
 *
 * These grids can be nested to create variations of layout usage.
 *
 * The grid should always be placed in such a way that it covers the full width of the window.
 *
 * Responsive:
 *
 * - On large screens the grid has 12 columns and margin on either side.
 * - On medium screens the grid has 8 columns.
 * - On small screens the grid has 4 columns.
 *
 * The grid can be configured to use a different number of columns for the content.
 * This will never exceed the number of columns available on the screen.
 * This property will be inherited by nested grids. This also determines the size of cards.
 *
 * For a visual representation of the grid:
 *
 * @see {@link ./examples/}
 */
export const pageGrid = (props: PageGridProps) => {
	return classes(pageGridBase, pageGridSprinkles(props));
};
