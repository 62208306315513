import { classes } from '@/uiPrimitives/base/classes.helpers';
import { pageGridItemSprinkles } from './pageGridItem.css';

/**
 * To place items in page grid in columns or rows
 */
export const pageGridItem = (
	props: Parameters<typeof pageGridItemSprinkles>[0],
) => {
	return classes(pageGridItemSprinkles(props));
};
